import { Injectable } from '@angular/core';
import { firstValueFrom, Observable } from 'rxjs';

import { APIResponse } from '../models/processes.model';
import { MainService } from './main.service';
import { WarehousePickup } from '../models/shipments.model';


@Injectable({
  providedIn: 'root'
})
export class SiteflowService {

  constructor(private main: MainService) { }


  /* async Promise<WarehousePickup | undefined> getShipmentData(1):
     Gets data related to the shipment's ID provided.
     < id: The ID of the shipment to be gotten.
     > shipmentData: Data of the shipment ID if true. */
  async getShipmentData(id: string): Promise<WarehousePickup | null> {
    let shipmentData: WarehousePickup | null = null;
    const response: APIResponse = await firstValueFrom(this.main.postRequest({ tracking_number: id }, '/getWPShipmentData'));

    if (response.data) {
      shipmentData = {
        currency: response.data.currency,
        ID: response.data.id_shipment,
        packages: response.data.packages,
        price: response.data.price,
        status: response.data.status,
        totalprice: response.data.totalprice,
        WPID: response.data.id_shipment_pickup_warehouse,
    } as WarehousePickup }

    return shipmentData;
  }


  /* async Promise<void> getShipmentData(1):
     Gets data related to the shipment's ID provided.
     < data: The data of the pickup to be registered.
     > response.success: True if the register was successful. */
  async registerWarehousePickup(data: FormData): Promise<boolean> {
    const response: APIResponse = await firstValueFrom(this.main.postRequestFormData(data, '/registerWarehousePickup'));
    return response.success;
  }
}
