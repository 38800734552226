export const environment = {
  production: false,
  // API Connection Values
  API_key: "buho2023*",
  API_URL: "http://localhost:4001",
  // Roles
  role_admin_id: 1,
  role_buho_id: 2,
  role_client_id: 3,
  roles: [{
    "id": 2,
    "rol": "Buho"
  } , {
    "id": 3,
    "rol": "Cliente"
  }]
};