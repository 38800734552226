import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

import { Core } from '../../core.module';
import { SiteflowService } from '../../services/siteflow.service';
import { WarehousePickup } from '../../models/shipments.model';

import Swal from 'sweetalert2';
import { firstValueFrom } from 'rxjs';


@Component({
  imports: [Core],
  selector: 'app-warehouse-pickups-form',
  standalone: true,
  styleUrl: './warehouse-pickups-form.component.scss',
  templateUrl: './warehouse-pickups-form.component.html'
})
export class WarehousePickupsFormComponent implements OnInit {
  // Form Handlers
  errorMessage: string | null = null;
  ID: string | null = null;
  pickup: WarehousePickup | null = null;
  wpForm!: FormGroup;
  // HTML View Controller Elements
  openCamera: boolean = false;                        // Camera Div
  loading: boolean = true;                            // Loading Screen
  photoPreview: ArrayBuffer | string | null = null;   // Photo Section
  requestLoading: boolean = false;                    // Loading Button Animation
  validID: boolean = false;                           // Main Rendering Fork
  @ViewChild('wpcanvas') wpCanvas!: ElementRef<HTMLCanvasElement>;  // Camera shutter
  @ViewChild('wpvideo') wpVideo!: ElementRef<HTMLVideoElement>;     // Input video stream
  
  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private sf: SiteflowService) {}
  
  ngOnInit(): void {
    this.extractShipmentData(); // Get tracking_number's ID
    this.createWPForm(); // Create form
  }


  /* void createWPForm(0):
     Creates the Warehouse Pickupse Form used for the component's process. */
  createWPForm(): void {
    this.wpForm = this.formBuilder.group({
      email: ['', [Validators.email]],
      id: ['', [Validators.required]],
      name: ['', [Validators.required]],
      phone: ['', [Validators.pattern(/^[0-9]{10}$/)]],
      photo: [null, Validators.required]
    });
  }

  /* void deletePicture(0):
     Deletes current value of photo control. */
  deletePicture(): void {
    Swal.fire({
      icon: 'warning',
      title: 'Borrar imagen',
      text: '¿Segur@ que quieres borrar la imagen actual?',
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      cancelButtonText: 'Atrás'
    }).then((answer: any) => {
      if (answer.isConfirmed) {
        this.photoPreview = null;
        this.wpForm.controls['photo'].setValue(this.photoPreview);
    }});
  }

  /* void replacePicture(2): 
     Fires a SweetAlert dialog to confirm if the existing data should be replaced.
     < file: The file to be set in the form group.
     < url: The data URL of the new picture. */
  replacePicture(file: File, url: string): void {
    Swal.fire({
      icon: 'warning',
      title: 'Confirmar cambios',
      text: 'Solamente recibimos una imagen. ¿Estás segur@ de querer reemplazar la imagen anterior?',
      showCancelButton: true,
      confirmButtonText: 'Reemplazar',
      cancelButtonText: 'Mantener anterior'
    }).then((answer: any) => {
      if (answer.isConfirmed) {
        this.photoPreview = url;
        this.wpForm.controls['photo'].setValue(file);
    }});
  }

  /* void shoot(0):
     Captures a frame from the video feed and sends it to preview. */
  shoot(): void {
    const canvas: HTMLCanvasElement = this.wpCanvas.nativeElement;
    const camera: CanvasRenderingContext2D | null = canvas.getContext('2d');
    const video: HTMLVideoElement = this.wpVideo.nativeElement;
    
    if (camera) {
      // Set canvas to cover video
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;

      // Shoot the camera
      camera.drawImage(video, 0, 0);

      // Reveal the picture
      canvas.toBlob((blob: Blob | null) => {
        if (blob) {
          // Convert BLOB to a file object
          const file: File = new File([blob], 'wp-evidence.jpeg', { type: 'image/jpeg' });
          const furl: string = URL.createObjectURL(file);
          // If there's already a picture, prompt before replacement
          if (this.photoPreview)
            this.replacePicture(file, furl);
          else {
            this.photoPreview = furl;
            this.wpForm.controls['photo'].setValue(file);
          }
      }}, 'image/jpeg');

      // Stop the feed
      this.openCamera = false;
      const stream: MediaStream = (video.srcObject as MediaStream);
      if (stream)
        stream.getTracks().forEach((i: MediaStreamTrack) => i.stop());
    } else {
      console.error('Error accessing the camera: Failed to get canvas context.');
      Swal.fire('Error', 'Parece que la cámara se atascó. Por favor intenta de nuevo.', 'error');
    }
  }

  /* void suspendCam(0):
     Suspends camera. */
  suspendCam(): void {
    const canvas: HTMLCanvasElement = this.wpCanvas.nativeElement;
    const camera: CanvasRenderingContext2D | null = canvas.getContext('2d');
    const video: HTMLVideoElement = this.wpVideo.nativeElement;

    if (camera) {
      // Stop the feed
      this.openCamera = false;
      const stream: MediaStream = (video.srcObject as MediaStream);
      if (stream)
        stream.getTracks().forEach((i: MediaStreamTrack) => i.stop());
    } else {
      console.error('Error accessing the camera: Failed to get canvas context.');
      Swal.fire('Error', 'Parece que la cámara se atascó. Por favor intenta de nuevo.', 'error');
    }
  }

  /* void uploadPicture(0):
     Opens a file input dialog to upload a picture and set 'photoPreview'. */
  uploadPicture(): void {
    // Set ghost input
    const input: HTMLInputElement = document.createElement('input');    
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = (event: any) => {
      const file: File = event.target.files[0]; // Get selected file
      if (file) {
        const furl: string = URL.createObjectURL(file);
        // If there's already a picture, prompt before replacement
        if (this.photoPreview)
          this.replacePicture(file, furl);
        else {
          this.photoPreview = furl;
          this.wpForm.controls['photo'].setValue(file);
    }}};
    // Trigger ghost input
    input.click();
  }
  

  /* async Promise<void> extractShipmentData(0):
    Gets the data embedded in the hyperlink and requests its information
    to the middleware. */
  async extractShipmentData(): Promise<void> {
    try {
      // Extract ID
      const x: ParamMap = await firstValueFrom(this.route.paramMap);
      this.ID = x.get('id');

      // If there's an ID to be extracted, get data
      if (this.ID)
        this.pickup = await this.sf.getShipmentData(this.ID);
      else
        throw new Error('No ID was received.');
    } catch (error: any) {
      if (error?.status && error?.status === 404)
        this.errorMessage = 'No se encontró el pedido con ID: ' + this.ID;
      else if (error?.status && (error?.status === 401 || error?.status === 403))
        this.errorMessage = 'Sin la contraseña, no. Acceso denegado.'
      else
        this.errorMessage = 'Ocurrió un error procesando la información del pedido.'
      console.error('Error retrieving parameter: ', error)
    }

    this.validID = (this.pickup !== null && this.pickup.status !== 'successful'); // If ID matches an existing shipping, and it hasn't been registered before, render.
    
    // If it was already registered and delivered
    if (this.pickup?.status === 'successful') {
      this.errorMessage = `El pedido ${ this.ID } ya se marcó como recolectado. Accede a la plataforma de Wing para consultar la evidencia proporcionada de su recolección en la sección "Envíos internos".`;
      this.loading = false;
      return;
    }
    
    this.wpForm.controls['id'].setValue(this.pickup?.ID.toString()); 
    this.loading = false; // Stop loading state.
  }

  /* async Promise<void> startCamera(0):
     Starts the camera feed and displays it in the video element. */
  async startCamera(): Promise<void> {
    try {
      // Request access to camera and open
      const video: HTMLVideoElement = this.wpVideo.nativeElement;
      const stream: MediaProvider = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' } });
      
      // Set source to media stream for camera and play
      video.srcObject = stream;
      video.play();
      this.openCamera = true;
    } catch (error) {
      console.error('Error accessing the camera:', error);
      Swal.fire('Error', 'No pudimos acceder a la cámara. Por favor revisa los ajustes de tu dispositivo.', 'error');
    }
  }

  /* async Promise<void> submit(0):
     Submits the form to the DB and fires an alert with the result of the process. */
  async submit(): Promise<void> {
    const record: FormData = new FormData();
    const photo: File = this.wpForm.controls['photo'].value;

    this.requestLoading = true;

    // Append fields
    record.append('email', this.wpForm.controls['email'].value || null);
    record.append('ID', this.wpForm.controls['id'].value);
    record.append('name', this.wpForm.controls['name'].value);
    record.append('phone', this.wpForm.controls['phone'].value || null);
    record.append('photo', photo, "evidence.jpg");
    record.append('trackingNumber', this.ID!);

    try {
      // Register values in DB
      const response: boolean = await this.sf.registerWarehousePickup(record);
      // Stop loading animation.
      this.requestLoading = false;
  
      if (response) {
        // If response is true, show a success alert and redirect to self
        Swal.fire({
          icon: 'success',
          title: '¡Listo!',
          text: 'Se registró correctamente la recolección.',
          confirmButtonText: 'OK'
        }).then((result) => {
          if (result.isConfirmed) this.router.navigate([`/warehouse-pickups/${ this.ID }`]);
        });
      } else {
        // If response is false, show an error alert
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Hubo un error registrando la recolección. Por favor, revisa los datos e intenta de nuevo.',
          confirmButtonText: 'OK'
        });
      }
    } catch (error) {
      // Fallback stop for loading animation on errors.
      this.requestLoading = false;

      // Handle unexpected errors
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Ocurrió un error inesperado.',
        confirmButtonText: 'OK'
      });
      console.error('Unexpected error:', error);
    }
  }
}
