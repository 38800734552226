<div class="container-fluid title-container">
  <div class="page-title">
    <div class="row">
      <h3>Recolección en almacén</h3>
    </div>
  </div>
</div>
<div class="container-fluid">
  <div class="card">
    <div class="card-body">
      <div *ngIf="loading">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
      <div class="row main-row" *ngIf="!loading && validID">
        <div class="col-lg-6 col-10">
          <div class="packdata-set">
            <div class="packdata-content">
              <h4 class="pd-title">Datos del pedido:</h4>
              <div class="row pd-field">
                <div><p>Número de rastreo:</p></div>
                <div><p><b>{{ ID }}</b></p></div>
              </div>
              <div class="row pd-field">
                <div><p>Contenido:</p></div>
                <div *ngFor="let x of pickup?.packages">
                  <p><b>{{ x.content }}</b> {{ (x.quantity > 1) ? '(' + x.quantity + ')' : '' }}</p>
                  <p>• {{ x.length }} x {{ x.width }} x {{ x.height }} cm</p>
                  <p>• {{ x.weight }} kg</p>
                </div>
              </div>
              <div class="row pd-field">
                <div><p>Costo total:</p></div>
                <div><p><b>$ {{ pickup?.totalprice }} <small>{{ pickup?.currency }}</small></b></p></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-10 col-12">
          <form [formGroup]="wpForm">
            <fieldset class="form-set">
              <legend class="mt-3">Datos de recolección:</legend>
              <hr class="my-4">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Nombre del recolector </mat-label>
                <input matInput formControlName="name">
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Teléfono </mat-label>
                <input matInput formControlName="phone">
                <mat-icon matSuffix>phone</mat-icon>
              </mat-form-field>
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Correo electrónico </mat-label>
                <input matInput formControlName="email">
                <mat-icon matSuffix>email</mat-icon>
              </mat-form-field>
              <hr class="my-3">
              <div class="img-upload-container">
                <h5 class="img-upload-title">Evidencia:
                  <mat-icon [ngStyle]="{'color': photoPreview ? '#9F19FF' : '#DDDDDD'}" style="transform: translateY(5px);">
                    {{ photoPreview ? 'check_circle' : 'error_outline' }}
                  </mat-icon>
                </h5>
                <div class="camera" [ngStyle]="{'display': openCamera ? 'flex' : 'none'}">
                  <div class="feed-container">
                    <video #wpvideo autoplay playsinline></video>
                    <canvas #wpcanvas style="display: none;"></canvas>
                    <div class="shoot-button" (click)="shoot()"><div></div></div>
                  </div>
                </div>
                <div class="preview-screen" *ngIf="photoPreview && !openCamera">
                  <img [src]="photoPreview" alt="Photo Preview">
                  <hr>
                </div>
                <div class="button-container" *ngIf="!openCamera; else cammenu">
                  <button mat-raised-button color="primary" class="main-button" (click)="uploadPicture()">Subir imagen</button>
                  <button mat-raised-button color="primary" class="main-button" (click)="startCamera()">Tomar foto</button>
                  <button mat-raised-button *ngIf="photoPreview" color="warn" class="main-button" (click)="deletePicture()">Borrar foto</button>
                </div>
                <ng-template #cammenu>
                  <button mat-raised-button color="warn" class="main-button" (click)="suspendCam()">Cancelar</button>
                </ng-template>
              </div>
              <div class="button-container my-3">
                <button mat-raised-button color="primary" class="main-button" type="submit" [disabled]="wpForm.invalid || requestLoading" (click)="submit()">
                  <ng-container *ngIf="requestLoading"><div class="checkout-spinner"><mat-spinner diameter="36"></mat-spinner></div></ng-container>
                  <ng-container *ngIf="!requestLoading">Enviar</ng-container>
                </button>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="row" *ngIf="!(loading || validID)">
        <div class="note-block">
          <p class="note">{{ errorMessage }}</p>
        </div>
      </div>
    </div>
  </div>
</div>