import { Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { WarehousePickupsFormComponent } from './components/warehouse-pickups-form/warehouse-pickups-form.component';


export const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'login' },
    { path: 'login', component: LoginComponent },
    { path: 'warehouse-pickups/:id', component: WarehousePickupsFormComponent },
];
