import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserLoginModel } from '../../models/users.model';
import { AuthService } from '../../services/auth.service';
import { NgForm, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatFormField } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ MatFormField,
    MatFormFieldModule,
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  user: UserLoginModel = new UserLoginModel();
  hide = true;
  
  constructor(
    private auth: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (this.auth.isAuthenticated())
      this.router.navigateByUrl('/admin/dashboard');

    if ( localStorage.getItem('email') ) {
      this.user.email = localStorage.getItem('email')!;
    }
  }

  login(form: NgForm) {

    if (form.invalid) { return; }

    this.user.email = this.user.email.trim();

    Swal.fire({
      icon: 'info',
      title: 'Espere...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    this.auth.login(this.user).subscribe(resp => {
      Swal.close();
      this.router.navigateByUrl('admin/dashboard');
    }, (err) => {
      console.log(err.error.message);
      Swal.fire({
        icon: 'error',
        title: 'Error al autenticar',
        text: 'Usuario o contraseña no válido'
      });
    });
  }

  openDialog() {
    Swal.fire({
      icon: 'warning',
      title: '¿Olvidó su contraseña?',
      text: 'Para recuperar su contraseña contacte a su agente de Búho o envié un mensaje solicitando el restablecimiento de su contraseña al correo electrónico "hola@buhologistics.com"',
      heightAuto: false
    });
  }

}
